import React from 'react'
import Wrap from 'components/wrap'

const Section = ({ bg = 'transparent', m = '0', wrap = false, children }) => (
  <div style={{ background: bg, margin: m }}>
    {wrap ? <Wrap>{children}</Wrap> : children}
  </div>
)

export default Section
