import React from 'react'
import styled from 'styled-components'
import { rem, swap } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { silver, iron } = theme.colors

const StyledCallout = styled.blockquote`
  border: solid ${silver};
  border-width: ${rem(1)} 0;
  color: ${iron};
  font-size: ${swap('small', rem(18), rem(28))};
  font-weight: 200;
  margin: ${rem(32)} 0;
  padding: ${rem(16)};
  position: relative;
  text-align: center;

  ${media.small`
    font-size: ${swap('small', rem(24), rem(32))};
  `}
`

const Callout = ({ children, ...rest }) => {
  return <StyledCallout {...rest}>{children}</StyledCallout>
}

export default Callout
