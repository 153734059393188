import React from 'react'
import styled from 'styled-components'
import { rem } from 'styled-tidy'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import Section from 'components/section'
import Callout from 'components/callout'
import Paragraph from 'components/paragraph'
import Image from 'components/image'

const Hands = styled(Image)`
  height: auto;
  margin: ${rem(32)} auto;
  max-width: ${rem(460)};
  width: 90%;
`

const AboutPage = () => (
  <Layout path="/about">
    <Section bg="#244da9">
      <Hands file="hands-blue.jpg" />
    </Section>
    <Wrap small>
      <Callout>
        Representing the harmony between light and dark, Evilneon is a
        partnership of two artistic voices.
      </Callout>
      <Paragraph>
        Evilneon espouses internal and external forces to bring a harmony of
        color and shape. Our art is influenced by the subject as much as the
        subject is influenced by the art.
      </Paragraph>
      <Paragraph>
        A combination of clean lines, vibrant colors, detail, and geometic
        symbolism are all earmarks of Evilneon, and our distinct artistic voice.
      </Paragraph>
      <Paragraph align="center">&middot; &middot; &middot;</Paragraph>
      <Paragraph>
        Experiments in color, materials, and technology in the 2000's is where
        the spark of Evilneon was concieved. In the summer of 2018, a crater
        blew, a creative color volcano, inspiring us to deliver Evilneon
        original paintings, prints, and portaits to the masses.
      </Paragraph>
      <Callout>
        Evilneon brings balance where we find chaos, chaos where we find beauty,
        and love from the darkest of corners of life.
      </Callout>
    </Wrap>
    <Section bg="#a22b80">
      <Hands file="hands-pink.jpg" />
    </Section>
  </Layout>
)

export default AboutPage
